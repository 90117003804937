

import React, { useState, useEffect } from 'react'
import { graphql, PageProps } from 'gatsby'
import classNames from 'classnames'
import { PrismicPagesQuery } from '../types/types.generated'
import { Seo } from '../components/Seo/Seo'
import { Intro } from '../components/Intro/Intro'
import { PAGES } from '../constants/pages'
import { Page } from '../components/Page/Page'

const IndexPage = (props: PageProps<PrismicPagesQuery>) => {
  const pages = [
    {
      intro: true,
      title: 'Opening Up The Outdoors',
      slug: '/',
      background: 'bg-blue',
      text: 'text-black',
    },
    props.data.pages.nodes.map((page) => {
      return {
        intro: false,
        title: page.data.title?.text,
        slug: `/${page.uid}`,
        body: page.data.body,
        background: `bg-${page.data.background_colour}`,
        text: page.data.background_colour === 'black' ? 'text-white' : 'text-black',
      }
    }).flat()
  ].flat()

  // @ts-expect-error index does exist
  const [openTab, setOpenTab] = useState<number|null>(props?.pageContext?.index ? props.pageContext.index : null)
  const [previousTab, setPreviousTab] = useState<number|null>(null)
  // @ts-expect-error index does exist
  const [hasClickedNav, setHasClickedNav] = useState<boolean>(props?.pageContext?.index ? true : false)

  const navigateToTab = (event: React.MouseEvent, index: number) => {
    event.preventDefault()
    setPreviousTab(openTab)
    setOpenTab(index)
    setHasClickedNav(true)

    const newUrl = PAGES[index].slug
    if (typeof window !== 'undefined' && typeof newUrl !== 'undefined') {
      window.history.pushState({}, '', newUrl);
    }
  }

  useEffect(() => {
    if (openTab === null) {
      setOpenTab(0)
    }
  }, [openTab])

  return (
    <>
      <Seo />
      <div className='flex flex-col lg:flex-row h-screen w-screen overflow-hidden'>
        {pages.map((tab, tabIndex) => {
          return (
            <div
              key={tabIndex}
              className={classNames('flex relative transition-all duration-1000', tab.background, tab.text, {
                'delay-1000': hasClickedNav,
                'h-full lg:w-full': openTab === tabIndex,
                'h-[52px] lg:h-full lg:w-14': openTab !== tabIndex,
                'h-1/5 lg:h-screen lg:w-1/5': openTab === null
              })}
            >
              <div
                className={classNames(
                  'relative z-30 w-full pointer-events-none transition-all',
                  'lg:w-14 lg:h-screen lg:ml-auto lg:static lg:z-0',
                )}
              >
                <a
                  href={tab.slug}
                  onClick={(event) => navigateToTab(event, tabIndex)}
                  className={classNames('block p-4 cursor-pointer pointer-events-auto lg:h-full outline-none relative z-30 lg:static top-0', tab.background,
                    {
                      'tab-index-0 transition-all duration-500': tabIndex === 0 && (openTab === 0 || previousTab === 0)
                    }
                  )}
                >
                  <h1
                    className={classNames('font-serif uppercase text-sm tracking-tight w-full lg:whitespace-nowrap lg:transform lg:rotate-90', {
                      'lg:opacity-0': tabIndex === 0 && openTab === 0,
                      'lg:opacity-100': tabIndex === 0  && openTab !== 0,
                      'lg:transition-opacity lg:duration-500 lg:delay-1000': tabIndex === 0,
                    })}
                  >
                    {tab.title}
                  </h1>
                </a>
              </div>
              <div
                className={classNames('transition-opacity duration-1000', {
                  'opacity-100 delay-1000': !hasClickedNav && openTab === tabIndex,
                  'opacity-100 delay-2000': hasClickedNav && openTab === tabIndex,
                  'opacity-0': openTab !== tabIndex,
                })}
              >
                <div
                  id={`tab-${tabIndex}`}
                  className={classNames('absolute w-screen lg:w-auto right-0 left-0 overflow-auto overscroll-auto', {
                    'lg:transition-right duration-0 delay-1000 lg:right-0': tabIndex === 0,
                    'lg:right-[56px]': tabIndex !== 0 || (tabIndex === 0 && openTab !== 0),
                    'top-0 bottom-[52px] lg:bottom-0': tabIndex === 0 && (openTab === 0 || previousTab === 0),
                    'top-[52px] bottom-0 lg:top-0': openTab !== 0 && tabIndex !== 0,
                  })}
                >
                  {[openTab, previousTab].includes(tabIndex) && (
                    <>
                      {tab.intro ? (
                        <Intro navigateToTab={navigateToTab} />
                      ) : (
                        <Page
                          navigateToTab={navigateToTab}
                          // @ts-expect-error
                          title={tab.title}
                          // @ts-expect-error
                          slices={tab.body}
                          background={tab.background}
                          tabIndex={tabIndex}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default IndexPage

export const PageQuery = graphql`
query PrismicPages {
  pages: allPrismicPage(sort: {order: ASC, fields: data___page_order}) {
    nodes {
      uid
      data {
        body {
          ... on PrismicPageDataBodyTextblock {
            id
            slice_type
            primary {
              content {
                html
              }
              heading {
                text
              }
            }
          }
          ... on PrismicPageDataBodyPeople {
            id
            slice_type
            items {
              role {
                text
              }
              description {
                html
              }
              hover_image {
                url
              }
              name {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicPageDataBodyFunders {
            id
            slice_type
          }
          ... on PrismicPageDataBodyFriends {
            id
            items {
              friend_title {
                text
              }
            }
            primary {
              heading {
                text
              }
              content {
                html
              }
            }
            slice_type
          }
          ... on PrismicPageDataBodyChangemakers {
            id
            slice_type
          }
          ... on PrismicPageDataBodySignupform {
            id
            slice_type
            primary {
              heading {
                text
              }
              content {
                html
              }
              type
            }
          }
          ... on PrismicPageDataBodyCommitments {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              commitment_heading
              commitment_body {
                html
              }
            }
          }
        }
        background_colour
        title {
          text
        }
      }
    }
  }
}
`