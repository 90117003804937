import React from 'react';
import classNames from 'classnames'

interface Props {
  formId: string;
  handleFormSubmit: (event: React.FormEvent) => void;
  isSubmitting: boolean;
  formElement: React.RefObject<HTMLFormElement>;
}

export const Form = ({ formId, handleFormSubmit, isSubmitting, formElement }: Props) => {
  const fields = [
    {
      name: 'FNAME',
      label: 'First Name',
      type: 'text'
    },
    {
      name: 'LNAME',
      label: 'Last Name',
      type: 'text'
    },
    {
      name: 'EMAIL',
      label: 'Email',
      type: 'email'
    }
  ]

  return (
    <form
      className={classNames('text-base max-w-xl pt-12 space-y-8', {
        'opacity-50': isSubmitting,
      })}
      onSubmit={handleFormSubmit}
      ref={formElement}
    >
      <div className='space-y-4'>
        {fields.map((field, fieldIndex) => {
          const fieldId =`${formId}-${field.name}`;
          return (
            <div
              key={fieldIndex}
              className='relative'
            >
              <label
                htmlFor={fieldId}
                className='sr-only'
              >
                {field.label}*
              </label>
              <input
                type={field.type}
                name={field.name}
                id={fieldId}
                placeholder={`${field.label}*`}
                className='w-full border-b border-current bg-transparent placeholder:uppercase placeholder:text-current h-12 outline-none rounded-none text-sm'
                required
                disabled={isSubmitting}
              />
            </div>
          )
        })}
      </div>
      <div>
        <button
          type='submit'
          className='font-serif bg-transparent border uppercase py-4 px-12 transition-colors signup-form-button'
          disabled={isSubmitting}
        >
          Submit
        </button>
        </div>
      <p className='heading underline-links-on-over'>By signing up you agree to OUTO’s <a href='https://itsgreatoutthere.com/privacy-policy/' target='_blank'>Privacy Policy</a>.</p>
    </form>
  )
}
