import React from 'react';
import classNames from 'classnames'
import { Section } from '../Section/Section';
import { PrismicPageDataBodyCommitments } from '../../types/types.generated'

interface Props {
  slice: PrismicPageDataBodyCommitments;
}

export const Commitments = ({ slice }: Props) => {
  return (
    <Section
      title={slice.primary.heading?.text || ''}
    >
      {slice.items.map((commitment, commitmentIndex) => {
        return (
          <div
            key={commitmentIndex}
            className={classNames('flex', {
              'border-t border-black pt-3': commitmentIndex !== 0,
              'pb-12': commitmentIndex !== slice.items.length - 1
            })}
          >
            <div className='w-16 xl:w-24 flex-shrink-0 heading pt-1'>
              <span>[{(commitmentIndex + 1).toString().padStart(2, '0')}]</span>
            </div>
            <div className='xl:flex'>
              <div className='xl:w-72 xl:flex-shrink-0 mb-12 xl:mb-0'>
                <h3 className='body'>{commitment.commitment_heading}</h3>
              </div>
              <div className='xl:pl-20'>
                {commitment.commitment_body?.html && (
                  <div
                    className='heading-normal'
                    dangerouslySetInnerHTML={{ __html: commitment.commitment_body.html }}
                  />
                )}
              </div>
            </div>
          </div>
        )
      })}
    </Section>
  )
}
