import React, { PropsWithChildren, ReactElement } from 'react'
import classNames from 'classnames'

interface Props  {
  title: string;
  className?: string;
  hideTitleOnMobile?: boolean;
  hideTitleOnDesktop?: boolean;
  hasBorderTop?: boolean;
}

export const Section = ({
  title,
  className,
  hideTitleOnMobile,
  hideTitleOnDesktop,
  hasBorderTop = true,
  children
}: PropsWithChildren<Props>): ReactElement => {
  return (
    <div className={classNames('lg:flex', className)}>
      <div className={classNames('heading-sans mb-8 lg:w-48 xl:w-72 lg:flex-shrink-0 lg:pr-4', {
        'hidden lg:block': hideTitleOnMobile || !title,
        'lg:pt-[0.75rem]': hasBorderTop,
        'lg:-mt-[0.175em]': !hasBorderTop
      })}>
        <h2
          className={classNames({
            'lg:sr-only': hideTitleOnDesktop,
          })}
        >
          {title}
        </h2>
      </div>
      <div className='lg:flex-grow lg:w-[70%] lg:px-4 lg:border-l lg:border-r lg:border-current'>
        <div
          className={classNames('body border-t border-current pt-3 pb-12', {
            'lg:border-0 lg:pt-0 lg:-mt-[0.175em]': !hasBorderTop
          })}
        >
          {children}
        </div>
      </div>
    </div>
  )
}