import React, { ReactElement, useState } from 'react'
import classNames from 'classnames'
import useApp from '../../hooks/useApp'

interface Props {
  background: string;
  tabIndex: number;
}

export const Cookies = ({ background, tabIndex }: Props): ReactElement | null => {
  const { setCookiesConsent, cookieBannerVisible } = useApp()

  const handleCookiePreference = (event: React.MouseEvent, value: boolean) => {
    event.preventDefault()
    // Set state
    setCookiesConsent(value)
  }

  if (!cookieBannerVisible) {
    return null
  }

  return (
    <div className={classNames('fixed z-50 lg:bottom-0 left-1/2 transform -translate-x-1/2 lg:translate-y-px max-w-xl w-full px-4 lg:px-0 pointer-events-none', {
      'bottom-[208px]': tabIndex === 0,
      'bottom-[156px]': tabIndex === 1,
      'bottom-[104px]': tabIndex === 2,
      'bottom-[52px]': tabIndex === 3,
      'bottom-0': tabIndex === 4,
    })}>
      <div className={classNames('text-sm border p-4 font-serif pointer-events-auto', background, {
        'border-black': background !== 'bg-black',
        'border-white': background === 'bg-black',
        'lg:-translate-x-[112px]': tabIndex === 0,
        'lg:-translate-x-[56px]': tabIndex === 1,
        'lg:translate-x-[56px]': tabIndex === 3,
        'lg:translate-x-[112px]': tabIndex === 4,
      })}>
        <p className='mb-4 leading-tight text-xs'>In order to provide you with an optimal experience, we use technologies such as cookies to store and/or access device information. If you consent to these technologies, we may process data such as browsing behavior or unique IDs on this website. If you do not give or withdraw your consent, certain features and functions may be affected.</p>
        <div className='flex space-x-8'>
          <button
            className='uppercase underline hover:no-underline'
            onClick={(event) => handleCookiePreference(event, true)}
          >
            Agree
          </button>
          <button
            className='uppercase underline hover:no-underline'
            onClick={(event) => handleCookiePreference(event, false)}
          >
            Decline
          </button>
          <a
            className='uppercase underline hover:no-underline'
            href='https://itsgreatoutthere.com/cookie-policy-eu/'
            target='_blank'
            aria-label='Learn more about the OUTO Cookie Policy'
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  )
}