import React, { ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { TopLevelProps } from '../../types/TopLevelProps'
import { Section } from '../Section/Section';
import ArrowIcon from '../../images/arrow.svg'
import { PAGES } from '../../constants/pages'
import { ContactQuery } from '../../types/types.generated'

interface Props extends TopLevelProps  {
  title?: string;
}

export const Contact = ({ title, navigateToTab }: Props): ReactElement => {
  const contact: ContactQuery = useStaticQuery(graphql`
    query contact {
      prismicContact {
        data {
          heading {
            text
          }
          email
          email_address
          address {
            html
          }
          newsletter_label
          legal_copy {
            html
          }
          partner_info {
            html
          }
        }
      }
    }
  `)

  return (
    <Section
      title={title ?? contact.prismicContact?.data.heading?.text ?? ''}
      hideTitleOnDesktop={!title}
    >
      <div className='heading-normal mb-8 md:grid md:grid-cols-2 md:gap-x-4 lg:grid-cols-1 xl:grid-cols-2'>
        <ul className='mb-4'>
          <li className='sr-only lg:not-sr-only'>
            <h2 className='heading'>{contact.prismicContact?.data.heading?.text}</h2>
          </li>
          {contact.prismicContact?.data.email && (
            <li className='flex'>
              <div className='w-20'>[EMAIL]</div>
              <div><a className='hover:underline' href={`mailto:${contact.prismicContact.data.email_address || contact.prismicContact.data.email}`}>{contact.prismicContact.data.email}</a></div>
            </li>
          )}
          {contact.prismicContact?.data?.address?.html && (
            <li className='flex'>
              <div className='w-20'>[ADDRESS]</div>
              <address
                className='not-italic'
                dangerouslySetInnerHTML={{ __html: contact.prismicContact.data.address.html }}
              />
            </li>
          )}
        </ul>
        <div>
          <a
            className='inline-flex items-center hover:underline'
            onClick={(event) => navigateToTab(event, 4)}
            href={PAGES[4].slug}
          >
            <ArrowIcon className='w-3 mr-2' />
            {contact.prismicContact?.data.newsletter_label}
          </a>
        </div>
      </div>
      {(contact.prismicContact?.data?.legal_copy?.html || contact.prismicContact?.data?.partner_info?.html) && (
        <div className='border-t border-black pt-3 body gap-y-4 grid md:grid-cols-2 md:gap-x-4 lg:grid-cols-1 xl:grid-cols-2 items-end'>
          {contact.prismicContact?.data?.legal_copy?.html && (
            <div
              className='heading underline-links-on-over'
              dangerouslySetInnerHTML={{ __html: contact.prismicContact.data.legal_copy.html }}
            />
          )}
          {contact.prismicContact?.data?.partner_info?.html && (
            <div
              className='heading underline-links'
              dangerouslySetInnerHTML={{ __html: contact.prismicContact.data.partner_info.html }}
            />
          )}
        </div>
      )}
    </Section>
  )
}
