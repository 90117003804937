import React, { ReactElement, useState, useRef } from 'react';
import { Section } from '../Section/Section';
import { Form } from '../Form/Form';
import { PrismicPageDataBodySignupform } from '../../types/types.generated'
// @ts-expect-error - there isn't a type for this package
import addToMailchimp from 'gatsby-plugin-mailchimp'
import axios from 'axios';

interface Props {
  slice: PrismicPageDataBodySignupform;
  hideTitleOnMobile?: boolean;
  hideTitleOnDesktop?: boolean;
  hasBorderTop?: boolean;
  pageTitle: string;
}

export const SignupForm = ({
  slice,
  hideTitleOnMobile,
  hideTitleOnDesktop,
  hasBorderTop = true,
  pageTitle
}: Props): ReactElement => {
  const formElement = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formMessage, setFormMessage] = useState<string>('');

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    setIsSubmitting(true)

    const formData = new FormData(event.target as HTMLFormElement)
    if (slice.primary.type === 'mailchimp') {
      addToMailchimp(formData.get('EMAIL'), {
        FNAME: formData.get('FNAME'),
        LNAME: formData.get('LNAME'),
      })
        // @ts-expect-error - no idea what the data is tbh
        .then((data) => {
          setIsSubmitting(false)
          setFormMessage(data.msg)

          if (formElement.current) {
            formElement.current.reset()
          }
        })
    } else {
      axios.post('/.netlify/functions/send-email', {
        EMAIL: formData.get('EMAIL'),
        FNAME: formData.get('FNAME'),
        LNAME: formData.get('LNAME'),
        PAGE: pageTitle,
        FORM_TITLE: slice.primary.heading?.text || '',
      })
        .catch((err) => {
          console.error('err', err);
        })
        .then((response) => {
          setIsSubmitting(false)
          setFormMessage(response?.data)

          if (formElement.current) {
            formElement.current.reset()
          }
        })
    }
  }

  return (
    <Section
      title={slice.primary.heading?.text || ''}
      className='signup-newsletter'
      hideTitleOnMobile={hideTitleOnMobile}
      hideTitleOnDesktop={hideTitleOnDesktop}
      hasBorderTop={hasBorderTop}
    >
      {slice.primary.content?.html && (
        <div
          className='section-body'
          dangerouslySetInnerHTML={{ __html: slice.primary.content.html }}
        />
      )}
      {formMessage && (
        <div className='heading pt-12'>{formMessage}</div>
      )}
      <Form
        formId={`form-${slice.id}`}
        handleFormSubmit={handleFormSubmit}
        isSubmitting={isSubmitting}
        formElement={formElement}
      />
    </Section>
  )
}
