import React, { ReactElement } from 'react';
import { Section } from '../Section/Section'
import { Founder } from './Founder'
import { PrismicPageDataBodyPeople } from '../../types/types.generated'

interface Props {
  slice: PrismicPageDataBodyPeople;
}

export const Founders = ({ slice }: Props): ReactElement => {
  return (
    <Section title='People'>
      <div className='md:grid md:gap-x-4 md:grid-cols-2 space-y-16 md:space-y-0'>
        {slice.items.map((founder, founderIndex) => {
          return (
            <Founder
              key={founderIndex}
              founderIndex={founderIndex}
              founder={founder}
            />
          )
        })}
      </div>
    </Section>
  )
}