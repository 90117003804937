import React, { Fragment, ReactElement } from 'react'
import classNames from 'classnames'
import { PrismicPagesQuery } from '../../types/types.generated'
import { TopLevelProps } from '../../types/TopLevelProps'
import { Seo } from '../Seo/Seo'
import { Cookies } from '../Cookies/Cookies';
import { Changemakers } from '../Changemakers/Changemakers';
import { Commitments } from '../Commitments/Commitments';
import { Contact } from '../Contact/Contact';
import { Friends } from '../Friends/Friends'
import { Founders } from '../Founders/Founders'
import { Funders } from '../Funders/Funders'
import { Section } from '../Section/Section';
import { SignupForm } from '../SignupForm/SignupForm';

interface Props extends TopLevelProps {
  title: string;
  slices: PrismicPagesQuery['pages']['nodes'][0]['data']['body'];
  background: string;
  tabIndex: number;
}

// @ts-expect-error
const renderBlock = (slice, sliceIndex: number, title: string) => {
  switch (slice.slice_type) {
    case 'textblock':
      return (
        <Section
          title={slice?.primary.heading.text}
          hideTitleOnMobile={sliceIndex === 0}
          hasBorderTop={sliceIndex > 0}
        >
          <div
            className='section-body'
            dangerouslySetInnerHTML={{ __html: slice?.primary.content.html }}
          />
        </Section>
      )

    case 'people':
        return (
          <Founders
            slice={slice}
          />
        )

    case 'funders':
        return (
          <Funders />
        )

    case 'friends':
      return (
        <Friends
          slice={slice}
        />
      )

    case 'changemakers':
      return (
        <Changemakers />
      )

    case 'signupform':
      return (
        <SignupForm
          slice={slice}
          hideTitleOnMobile={sliceIndex > 0}
          hasBorderTop={sliceIndex > 0}
          pageTitle={title}
        />
      )

    case 'commitments':
      return (
        <Commitments
          slice={slice}
        />
      )

    default:
      return (
        <div>
          Not found for {slice.slice_type}
        </div>
      )
  }
}

export const Page = ({ title, slices, background, navigateToTab, tabIndex }: Props): ReactElement => {
  return (
    <>
      <Seo
        title={title}
      />
      <div
        className={classNames('p-4 lg:pr-0', background)}
      >
        {slices.map((slice, sliceIndex) => {
          return (
            <Fragment key={slice.id}>
              {renderBlock(slice, sliceIndex, title)}
            </Fragment>
          )
        })}
        <Contact
          navigateToTab={navigateToTab}
        />
      </div>
      <Cookies
        background={background}
        tabIndex={tabIndex}
      />
    </>
  )
}
