//import { Allyship } from '../components/Allyship/Allyship'
//import { Changemaker } from '../components/Changemaker/Changemaker'
//import { Intro } from '../components/Intro/Intro'
//import { SignUp } from '../components/SignUp/SignUp'
//import { WhoWeAre } from '../components/WhoWeAre/WhoWeAre'

export const PAGES = [
  {
    index: 0,
    title: 'Opening Up The Outdoors',
    slug: '/',
    bgColor: 'bg-blue',
    textColor: 'text-black',
    //component: Intro,
  },
  {
    index: 1,
    title: 'Who We Are',
    slug: '/who-we-are',
    bgColor: 'bg-paper',
    textColor: 'text-black',
    //component: WhoWeAre,
  },
  {
    index: 2,
    title: 'The Changemaker Programme',
    slug: '/the-changemaker-programme',
    bgColor: 'bg-orange',
    textColor: 'text-black',
    //component: Changemaker,
  },
  {
    index: 3,
    title: 'The Online Allyship Commitment',
    slug: '/the-online-allyship-commitment',
    bgColor: 'bg-gold',
    textColor: 'text-black',
    //component: Allyship,
  },
  {
    index: 4,
    title: 'Sign Up',
    slug: '/sign-up',
    bgColor: 'bg-black',
    textColor: 'text-white',
    //component: SignUp,
  },
]
