import React, { ReactElement } from 'react';
import { Section } from '../Section/Section';
import { PrismicPageDataBodyFriends } from '../../types/types.generated'

interface Props {
  slice: PrismicPageDataBodyFriends
}

export const Friends = ({ slice }: Props): ReactElement => {
  return (
    <Section
      title={slice.primary.heading?.text || ''}
    >
      {slice.primary.content?.html && (
        <div
          className='body mb-4 md:mb-8'
          dangerouslySetInnerHTML={{ __html: slice.primary.content.html }}
        />
      )}
      <ul className='md:columns-2 lg:columns-1 xl:columns-2'>
        {slice.items.map((friend, friendIndex) => {
          return (
            <li key={friendIndex}>
              {friend.friend_title?.text}
            </li>
          )
        })}
      </ul>
    </Section>
  )
}


