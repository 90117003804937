import React, { ReactElement, useRef, useEffect, useState } from 'react';
import classNames from 'classnames'
import { PrismicPageDataBodyPeopleItem } from '../../types/types.generated'

interface Props {
  founder: PrismicPageDataBodyPeopleItem;
  founderIndex: number;
}

export const Founder = ({ founder, founderIndex }: Props) => {
  return (
    <div
      className={classNames('heading-normal mb-16 md:mb-0', {
        'border-t border-black pt-3 md:border-none md:pt-0': founderIndex !== 0
      })}
    >
      <div className='flex justify-between mb-8 md:block'>
        <div className='w-40 md:w-full order-2 md:mb-5 relative group'>
          <img
            src={founder.image?.url}
            alt={founder.name?.text}
            className='mix-blend-multiply'
          />
          <div
            className='absolute z-20 pointer-events-none transition-opacity opacity-0 group-hover:opacity-100 top-0 left-0'
          >
            <img
              src={founder.hover_image?.url}
              alt={founder.name?.text}
              className='w-full h-auto max-w-none'
            />
          </div>
        </div>
        <div className='order-1'>
          <h3 className='uppercase'>{founder.name?.text}</h3>
          <h4>{founder.role?.text}</h4>
        </div>
      </div>
      {founder.description?.html && (
        <div
          className='founder-body'
          dangerouslySetInnerHTML={{ __html: founder.description.html }}
        />
      )}
    </div>
  )
}