import React, { ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import { Section } from '../Section/Section';
import { ChangemakersQuery } from '../../types/types.generated'

export const Changemakers = (): ReactElement => {
  const changemakers: ChangemakersQuery = useStaticQuery(graphql`
    query changemakers {
      prismicChangemakers {
        data {
          heading {
            text
          }
          body {
            ... on PrismicChangemakersDataBodyChangemaker {
              id
              primary {
                pronouns
                name
                image {
                  url
                }
                description {
                  html
                }
                hover_image {
                  url
                }
                hover_image_style
              }
              items {
                link_url
                link_label
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section
      title={changemakers.prismicChangemakers?.data.heading?.text || ''}
    >
      <div className='grid md:grid-cols-2 md:gap-x-16 gap-y-20'>
        {changemakers.prismicChangemakers?.data.body.map((changemaker, changemakerIndex) => {
          const imageHoverClassname = classNames('absolute hidden lg:block z-20 pointer-events-none transition-opacity opacity-0 group-hover:opacity-100', {
            'w-[150%] top-1/2 left-1/2 transform -translate-x-[45%] -translate-y-[45%]': changemaker.primary.hover_image_style === '3d_box',
            'w-[160%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2': changemaker.primary.hover_image_style === 'curly_outline',
            'w-[150%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2': changemaker.primary.hover_image_style === 'comic_outline',
            'w-[180%] top-1/2 left-1/2 transform -translate-x-[44%] -translate-y-[45%]': changemaker.primary.hover_image_style === 'magic_mirror',
            'w-[190%] top-1/2 left-1/2 transform -translate-x-[45%] -translate-y-[63%]': changemaker.primary.hover_image_style === 'speech',
            'w-[120%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[60%]': changemaker.primary.hover_image_style === 'picture_frame',
            'w-[130%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[55%]': changemaker.primary.hover_image_style === 'squiggles',
            'w-[155%] top-1/2 left-1/2 transform -translate-x-[45%] -translate-y-1/2': changemaker.primary.hover_image_style === 'comic_outline_minimal',
          })
          return (
            <div
              key={changemakerIndex}
              className={classNames({
                'border-t border-black pt-3 md:border-none md:pt-0': changemakerIndex !== 0
              })}
            >
              <div className='flex justify-between lg:block'>
                <div className='w-40 mb-8 order-2 relative group'>
                  <img
                    src={changemaker.primary.image?.url}
                    alt={changemaker.primary.name}
                    className='mix-blend-multiply'
                  />
                  {changemaker.primary.hover_image?.url && (
                    <div className={imageHoverClassname}>
                      <img
                        src={changemaker.primary.hover_image?.url}
                        alt={changemaker.primary.name}
                        className='w-full h-auto max-w-none'
                      />
                    </div>
                  )}
                </div>
                <h3 className='heading mb-8 order-1 mr-4 lg:mr-0'>{changemaker.primary.name}{changemaker.primary.pronouns && (
                  <span className='heading-normal'> ({changemaker.primary.pronouns})</span>
                )}</h3>
              </div>
              {changemaker.primary.description?.html && (
                <div
                  className='heading-normal mb-8'
                  dangerouslySetInnerHTML={{ __html: changemaker.primary.description.html }}
                />
              )}
              {changemaker.items?.length && (
                <ul className='heading-normal'>
                  {changemaker.items.map((link, linkIndex) => {
                    return (
                      <li
                        key={`${changemakerIndex}_${linkIndex}`}
                      >
                        <a
                          href={link.link_url}
                          target='_blank'
                          rel='noopener'
                          className='underline hover:no-underline'
                        >
                          {link.link_label}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          )
        })}
      </div>
    </Section>
  )
}