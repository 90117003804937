import React, { ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section } from '../Section/Section';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { FundersQuery } from '../../types/types.generated'

export const Funders = (): ReactElement => {
  const funders: FundersQuery = useStaticQuery(graphql`
    query funders {
      prismicFunders {
        data {
          title {
            text
          }
          funders {
            funder_title {
              text
            }
            link_url {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <Section
      title={funders.prismicFunders?.data.title?.text || ''}
    >
      <ul className='md:grid md:grid-cols-2 md:gap-x-4 relative -mb-12 pb-12'>
        {funders.prismicFunders?.data?.funders?.map((funder, funderIndex) => {
          return (
            <li
              key={funderIndex}
              className='group'
            >
              <ConditionalWrapper
                condition={!!funder?.link_url?.url}
                wrapper={(children) => (
                  <a href={funder?.link_url?.url} target='_blank' rel='noopener noreferrer'>
                    {children}
                  </a>
                )}
              >
                {funder?.funder_title?.text}
              </ConditionalWrapper>
            </li>
          )
        })}
      </ul>
    </Section>
  )
}