import React, { ReactElement, ReactNode } from 'react';

export interface Props {
  condition: boolean;
  wrapper: (children: ReactNode) => void;
  children?: React.ReactNode;
}

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: Props): ReactElement => {
  return <>{condition ? wrapper(children) : children}</>;
};
