import React, { ReactElement } from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import { Seo } from '../Seo/Seo'
import { Cookies } from '../Cookies/Cookies';
import { TopLevelProps } from '../../types/TopLevelProps'
import Logo from '../../images/logo.svg'
import ArrowIcon from '../../images/arrow.svg'
import { IntroQuery } from '../../types/types.generated'

export const Intro = ({ navigateToTab }: TopLevelProps): ReactElement => {
  const links = [
    'Sign Up',
    'Contact',
  ]

  const intro: IntroQuery = useStaticQuery(graphql`
    query intro {
      prismicIntro {
        data {
          info_copy {
            html
          }
          info_hover_copy_left {
            html
          }
          info_hover_copy_right {
            html
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo
          title='Opening Up The Outdoors'
        />
      <div className='bg-blue px-4 lg:px-5 py-4 lg:pt-12 lg:h-full lg:flex lg:flex-col lg:justify-between'>
        <div>
          <Logo />
        </div>
        {intro.prismicIntro?.data.info_copy?.html && (
          <div className='flex items-start lg:hidden pt-3 mt-3 border-t border-black'>
            <ArrowIcon className='w-3 mr-2 flex-shrink-0 mt-[0.2em]' />
            <div
              className='text-[0.64rem] sm:text-xs lg:text-sm normal-case font-serif'
              dangerouslySetInnerHTML={{ __html: intro.prismicIntro.data.info_copy.html }}
            />
          </div>
        )}
        <div className='absolute bottom-0 left-0 w-full px-4 lg:px-5 lg:pb-4 sr-only lg:not-sr-only'>
          <div className='pt-3 border-t border-black' />
          <div className='flex justify-between items-start lg:space-x-4'>
            <div className='flex items-center space-x-6 xl:space-x-16'>
              <h1 className='heading'>Opening Up The Outdoors</h1>
              <ul className='flex space-x-6 xl:space-x-8 heading'>
                {links.map((link, linkIndex) => {
                  return (
                    <li key={linkIndex}>
                      <a
                        href='/sign-up'
                        onClick={(event) => navigateToTab(event, 4)}
                        className='heading inline-flex items-center hover:underline'
                      >
                        <ArrowIcon className='w-3 mr-2' />
                        {link}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
            {intro.prismicIntro?.data.info_copy?.html && (
              <div className='relative group lg:w-[360px] xl:w-[500px]'>
                <div className='flex items-start'>
                  <ArrowIcon className='w-3 mr-2 flex-shrink-0 mt-[0.4em]' />
                  <div
                    className='text-[0.64rem] sm:text-xs lg:text-sm normal-case font-serif'
                    dangerouslySetInnerHTML={{ __html: intro.prismicIntro.data.info_copy.html }}
                  />
                  {(intro.prismicIntro?.data.info_hover_copy_left?.html || intro.prismicIntro?.data.info_hover_copy_right?.html) && (
                    <div className='grid grid-cols-2 gap-x-4 absolute left-0 bottom-full pb-6 opacity-0 group-hover:opacity-100 transition-opacity'>
                      {intro.prismicIntro?.data.info_hover_copy_left?.html && (
                        <div
                          className='text-[0.6rem] sm:text-xs lg:text-sm normal-case font-serif'
                          dangerouslySetInnerHTML={{ __html: intro.prismicIntro.data.info_hover_copy_left.html }}
                        />
                      )}
                      {intro.prismicIntro?.data.info_hover_copy_right?.html && (
                        <div
                          className='text-[0.6rem] sm:text-xs lg:text-sm normal-case font-serif'
                          dangerouslySetInnerHTML={{ __html: intro.prismicIntro.data.info_hover_copy_right.html }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <Cookies
          background='bg-blue'
          tabIndex={0}
        />
      </div>
    </>
  )
}
